
.release-list-item, .section-list-item {
    display: block;
    color: $dark;
    text-decoration: none;
    &:hover {
        color: $dark;
        text-decoration: none;
    }
    .release-label, .section-label {
        width: 100%;
        font-size: 1.4em;
        font-weight: bold;
    }
}

.book-list-item {
    display: inline-block;
    color: $dark;
    text-decoration: none !important;
    // width: 120px;
    max-width: 100%;
    // @include media-breakpoint-up(sm) {
    //     width: 150px;
    // }
    // @include media-breakpoint-up(md) {
    //     width: 175px;
    // }
    a {
        color: $dark;
        text-decoration: none !important;
    }
    img {
        max-width: 100%;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
        min-width: 82px;
    }
    .book-title {
        font-weight: 600;
        font-size: 1.25em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 70px;
    }
    &.full-info {
        p {
            margin: 0 0 2px;
            font-size: .8em;
            &.book-title {
                font-size: 1em;
            }
            &.book-title, &.price {
                margin: 0 0 8px;
            }
        }
    }
}

.offer-list-item {
    color: $dark;
    text-decoration: none !important;
    .card {
        transition: transform .2s;
    }
    .card-title {
        font-weight: bold;
    }
    .card-subtitle {
        font-size: small;
    }
    .card-text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 70px; // for browsers that don't support line-clamp
    }
    .card::after {
        content: " ";
        display: block;
        background: $primary;
        width: 0%;
        height: 3px;
        transition: width .2s;
    }
    &:hover {
        .card {
            transform: translate(0, -5px);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
        }
        .card::after {
            width: 100%;
        }
    }
}

.offer-featured-item {
    .featured-img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 50%;
        height: 0;
        @include media-breakpoint-up(sm) {
            height: 100%;
            // padding-top: 0;
        }
    }
    .featured-subtitle {
        font-size: small;
    }
    .featured-desc {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 120px;
    }
}
.page.offer .offer-featured-item .featured-img {
  @include media-breakpoint-up(sm) {
    padding-top: 100%;
  }
  @include media-breakpoint-up(md) {
    padding-top: 50%;
  }
}

.order-list-item {
    color: $dark;
    text-decoration: none !important;
    .order-summary {
        p {
            font-size: 90%;
        }
        .order-status {
          font-weight: bold;
        }
    }
}
