// override bootstrap variables here

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 900px,
  xl: 1200px,
  xxl: 1400px
);

$font-family-sans-serif: "Hind", Arial, sans-serif;
$headings-font-family: "Libre Baskerville", serif;
$primary: #58595b;
$secondary: #999999;
$dark: #231f20;
$grid-gutter-width: 32px;
$btn-border-radius: 1.2em;
$btn-border-radius-lg: 1.2em;
$btn-border-radius-sm: 1.2em;
$btn-padding-x: 1.5rem;
$btn-padding-x-sm: .8rem;
$input-line-height: 2.2;
$font-size-base: 0.9rem;
$font-weight-bold: 600;
$navbar-nav-link-padding-x: 1.3rem;
$navbar-dark-color: #fff;
$card-border-radius: 0;
$card-inner-border-radius: 0;

$carousel-control-width: 10%;
$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-spacer: 5px;

@import '~bootstrap/scss/variables';


$container-max-widths: ( sm: 1300px, md:1300px, lg:1300px, xl:1300px );
