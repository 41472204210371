// additional css for bootstrap components used throughout the site

.navbar-brand {
    svg {max-height: 60px; width: auto;}
}

.card-img-top.square {
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.carousel {
    .carousel-indicators {
        justify-content: right;
        li {
            border-radius: 50%;
        }
    }
    .carousel-item {
        text-decoration: none;
        color: $dark;
        padding-bottom: 60%;
        @include media-breakpoint-up(sm) {
          padding-bottom: 50%;
        }
        @include media-breakpoint-up(md) {
          padding-bottom: 40%;
        }
        > * {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $secondary;
        }
    }

}

.accordion.faq {
    .card {
        border-left: none;
        border-right: none;
    }
    .card-header {
        cursor: pointer;
        background: none;
        color: $primary;
        font-size: 150%;
        padding-left: 0;
        padding-right: 80px;
        &::after {
            content: "+";
            font-family: Arial;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: .75rem;
            right: 0;
            color: $primary;
            background-color: #fff;
            border: 1px solid $primary;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            font-size: 30px;
            line-height: 27px;
            transition: transform .2s, color .2s, background-color .2s;
        }

    }
    .card.active .card-header::after {
        transform: rotate(45deg);
        color: #fff;
        background-color: $primary;
    }
    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}
