@import "~bootstrap/scss/functions";
@import "../../styles/variables";

#main-header {
    font-size: 1rem;
    .navbar-brand {
        color: $primary;
    }
    .nav-link {
        font-weight: 600;
        color: $dark;
        &.active {
            color: $primary;
        }
    }
    .search-field.expanded input {
        max-width: 200px;
    }
}

#account-header {
    a:not(.dropdown-item), button:not(.dropdown-item) {
        text-decoration: none;
        padding: 0;
        color: $white;
        display: inline-block;
        vertical-align: middle;
        &:hover {
            color: $gray-300;
        }
    }
    svg {
        vertical-align: top;
    }
}

#account-nav-toggle {
}
